<template>
  <div class="quarter-filter-container">
    <span> De </span>
    <v-select
      v-model="internalValue.trimestreIni"
      :items="quarterOptions"
      :aria-label="'filtroTrimestreDe'"
      placeholder="nenhum trimestre"
      dense
      hide-details
      @change="changeHandler('trimestreIni', $event)"
    ></v-select>
    <span> até </span>
    <v-select
      v-model="internalValue.trimestreFim"
      :items="quarterOptions"
      placeholder="mesmo trimestre"
      :aria-label="'filtroTrimestreAte'"
      dense
      hide-details
      clearable
      @change="changeHandler('trimestreFim', $event)"
    ></v-select>
    <span> de </span>
    <year-select @change="changeHandler('anoBase', $event)" :aria-label="'filtroTrimestreAnoBase'"></year-select>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  components: {
    "year-select": () => import("@/components/year-select.vue"),
  },
  computed: {
    ...mapGetters(["anoBase"]),
    quarterOptions: function () {
      return [
        {
          text: "Primeiro trimestre",
          value: 1,
        },
        {
          text: "Segundo trimestre",
          value: 2,
        },
        {
          text: "Terceiro trimestre",
          value: 3,
        },
        {
          text: "Quarto trimestre",
          value: 4,
        },
      ];
    },
  },
  data: function () {
    return {
      internalValue: this.value,
    };
  },
  props: {
    value: {
      type: Object,
      default: () => ({
        trimestreIni: 1,
        trimestreFim: null,
      }),
    },
  },
  model: {
    prop: "value",
    event: "change",
  },
  methods: {
    changeHandler: function (fieldName, fieldValue) {
      this.internalValue = {
        ...this.internalValue,
        [fieldName]: fieldValue,
      };
      const { trimestreIni, trimestreFim } = this.internalValue;

      if (trimestreFim && trimestreIni > trimestreFim) {
        this.internalValue.trimestreIni = trimestreFim;
        this.internalValue.trimestreFim = trimestreIni;
      }

      this.$emit("update:value", this.internalValue);
      this.$emit("change", this.internalValue);
    },
  },
  name: "quarter-filter",
  watch: {
    value: {
      handler: function (nextValue) {
        this.internalValue = nextValue;
      },
      deep: true,
    }
  }
};
</script>

<style lang="scss" scoped>
.quarter-filter-container {
  align-items: baseline;
  display: flex;
  height: 32px;

  * {
    height: 32px;
    margin: 0 0.25rem;
  }

  span {
    max-width: min-content;
  }

  :nth-child(6) {
    max-width: min-content;
  }
}

::v-deep .v-input__prepend-outer {
  display: none;
}
</style>
